import * as React from "react";
const Cloudbg = (props) => { 
  const [resolution, setResolution] = React.useState('1080p');

  React.useEffect(() => {
    const updateResolution = () => {
        if (window.innerWidth >= 2560) { 
            setResolution('1440p');
        } else {
            setResolution('1080p');
        }
    };

    updateResolution(); // Call on component mount
    window.addEventListener('resize', updateResolution);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', updateResolution);
}, []);

  return (
      <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox={resolution === '1440p' ? '0 0 2560 1440' : '0 0 1920 1080'}
      preserveAspectRatio='xMidYMid'
      style={{
        shapeRendering: "auto",
        display: "block",
        background: "rgb(221, 160, 221)",
      }}
      width={resolution === '1440p' ? 2560 : 1920} 
      height={resolution === '1440p' ? 1440 : 1080} 
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g>
        <defs>
          <filter id='f-oersv4ab58h' x={-2} y={-2} width={6} height={6}>
            <feTurbulence
              type='fractalNoise'
              baseFrequency={0.004}
              numOctaves={2}
              result='turb'
            />
            <feColorMatrix type='hueRotate' values={0}>
              <animate
                attributeName='values'
                dur='5s'
                repeatCount='indefinite'
                keyTimes='0;1'
                values='0;360'
              />
            </feColorMatrix>
            <feColorMatrix
              type='matrix'
              values=' 0 0 0 0 1  0 0 0 0 0.8 0 0 0 0 0.6 1 0 0 0 0 '
              result='cloud'
            />
          </filter>
        </defs>
        <rect
          x={0}
          y={0}
          width={resolution === '1440p' ? 2560 : 1920} 
          height={resolution === '1440p' ? 1440 : 1080} 
          filter='url(#f-oersv4ab58h)' 
        />
        <g />
      </g>
    </svg>
  );
};
export default Cloudbg;
