import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavControl from './components/common/Nav'; 
import Resume from './components/Resume';
import Projects from './components/Projects';
import ErrorPage from './components/ErrorPage';
// import Cloudbg from './components/Cloudbg';
import MainLayout from './components/MainLayout'; 
import './App.css';  
  
function App() {  
    return ( 
        <div> 
          <Router> 
              <NavControl />  
                <Routes>
                  <Route path="/" element={<MainLayout />} />  
                  <Route path="*" element={<ErrorPage />} />
                  <Route path="/Projects" element={<Projects />}/>
                  <Route path="/Resume" element={<Resume />}/>
                </Routes>  
          </Router> 
        </div>
    );
  } 
export default App;
