import React, { useRef, useLayoutEffect } from 'react';
import styles from '../../styles/Home.module.css'; 
const rippleSettings = {
  maxSize: 125,
  animationSpeed: 1,
  strokeColor: [142, 189, 255],
};

const canvasSettings = {
  blur: 8,
  ratio: 1,
};

function Coords(x, y) {
  this.x = x || null;
  this.y = y || null;
}

const Ripple = function Ripple(x, y, circleSize, ctx) {
  this.position = new Coords(x, y);
  this.circleSize = circleSize;
  this.maxSize = rippleSettings.maxSize;
  this.opacity = 1;
  this.ctx = ctx;
  this.strokeColor = `rgba(${Math.floor(rippleSettings.strokeColor[0])},
    ${Math.floor(rippleSettings.strokeColor[1])},
    ${Math.floor(rippleSettings.strokeColor[2])},
    ${this.opacity})`;

  this.animationSpeed = rippleSettings.animationSpeed;
  this.opacityStep = (this.animationSpeed / (this.maxSize - circleSize)) / 2;
};

Ripple.prototype = {
  update: function update() {
    this.circleSize = this.circleSize + this.animationSpeed;
    this.opacity = this.opacity - this.opacityStep;
    this.strokeColor = `rgba(${Math.floor(rippleSettings.strokeColor[0])},
      ${Math.floor(rippleSettings.strokeColor[1])},
      ${Math.floor(rippleSettings.strokeColor[2])},
      ${this.opacity})`;
  },
  draw: function draw() {
    this.ctx.beginPath();
    this.ctx.strokeStyle = this.strokeColor;
    this.ctx.arc(this.position.x, this.position.y, this.circleSize, 0, 2 * Math.PI);
    this.ctx.stroke();
  },
  setStatus: function setStatus(status) {
    this.status = status;
  },
};

const RippleCanvas = () => {
    const canvasRef = useRef(null);
    const containerRef = useRef(null); 
    const ripples = [];
  
    useLayoutEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const container = containerRef.current;
      canvas.style.filter = `blur(${canvasSettings.blur}px)`; 
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.target === container) {
            const { width, height } = entry.contentRect;
            canvas.width = width * canvasSettings.ratio;
            canvas.height = height * canvasSettings.ratio;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${height}px`;
          }
        }
      });
      resizeObserver.observe(container);
  
      const animation = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ripples.forEach((ripple, index) => {
          ripple.update();
          ripple.draw();
          if (ripple.opacity <= 0) {
            ripples.splice(index, 1);
          }
        });
        requestAnimationFrame(animation);
      };
  
      const handleMouseMove = (e) => {
        const rect = canvas.getBoundingClientRect();
        const x = (e.clientX - rect.left);
        const y = (e.clientY - rect.top);
        ripples.unshift(new Ripple(x, y, 2, ctx));
      };
  
      canvas.addEventListener('mousemove', handleMouseMove);
      requestAnimationFrame(animation);
  
      return () => {
        canvas.removeEventListener('mousemove', handleMouseMove);
        resizeObserver.disconnect();
      };
    }, []);
  
    return <div ref={containerRef} className={styles.rippleCanvas}> 
            <h3 className={styles.rippleText}>Create Cool Waves 😎</h3>
            <canvas ref={canvasRef} />
           </div>;
  };
  
  export default RippleCanvas;