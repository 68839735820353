  // { id: 1, title: "Project One", description: '• Pioneered the development and implementation of a cross-application location tracking feature, utilizing the .NET Framework to optimize data flow, transforming the user experience from a manual PDF download-and-search process to an interactive UI. This enhancement allows users to search for location data by districts and counties within the state, significantly improving usability and efficiency.\n• Developed a custom library and implemented user/role management across multiple web applications, using .NET Framework and Oracle DB. This innovation significantly reduced manual DevOps workload and enhanced administrative efficiency.\n• Managed lifecycle of .NET and Java applications, from development in C# and Java to deployment and configuration via IIS and Wildfly, ensuring robust performance and scalability.\n• Led Java backend improvements, routinely executing bug fixes, feature additions, and configuration modifications, employing Spring Boot, JSF, Maven, and continuous integration practices.\n• Collaborated on testing and documentation, driving quality assurance, and leveraging extensive code reviews to uphold high standards in code health and application stability.' },
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import projectBg1 from '../img/project_bg1.svg';
import projectBg2 from '../img/project_bg2.svg';
import projectBg3 from '../img/project_bg3.svg';
import projectBg4 from '../img/project_bg4.svg'; 
import '../styles/Project.scss'; 
import projects from './common/data';


 
  
  // const projects = [
  //   { id: 1, title: "Project One", description: ""},
  //   { id: 2, title: "Project Two", description: ""},
  //   { id: 3, title: "Project Three", description: ""}
  // ];
  
  const formatDescription = (description) => {
    const lines = description.split('\n');
    return lines.map((line, index) => {
      const additionalIndent = index > 0 ? 'indented' : '';
      return line.includes('•') ? (
        <p key={index}>{line}</p>
      ) : (
        <p key={index} className={additionalIndent}>{line}</p>
      );
    });
  };//NEED better improvements
 
  
  export default function Project() {
    const [activeSection, setActiveSection] = useState(0);
    const location = useLocation();
  
    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight / 2;
        const sectionPositions = projects.map((_, index) =>
          document.getElementById(`section${index + 1}`).offsetTop
        );
  
        const currentSection = sectionPositions.findIndex(
          (position, index) =>
            scrollPosition >= position &&
            (index === sectionPositions.length - 1 || scrollPosition < sectionPositions[index + 1])
        );
  
        setActiveSection(currentSection);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    
  useEffect(() => {
    // Scroll to the section if a hash is present in the URL
    if (location.hash) {
      const sectionId = location.hash.replace('#', '');
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const backgrounds = {
    1: projectBg1,
    2: projectBg2,
    3: projectBg3,
    4: projectBg4,
  };

    const isProjectPage = location.pathname === '/Projects';
  
    return (
      <div className="projects-page">
        {isProjectPage && (
        <div className="nav-control">
          {projects.map((project, index) => (
            <a
              key={project.id}
              href={`#section${index + 1}`}
              className={`nav-circle ${activeSection === index ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                document.getElementById(`section${index + 1}`).scrollIntoView({ behavior: 'smooth' });
              }}
            ></a>
          ))}
        </div> )}
        <div className="projects">
          {projects.map(({ id, title, descriptions }, index) => {
            const sectionClass = `section${index + 1}`;
            const backgroundImage = backgrounds[id];
  
            return (
              <div key={id} className={`project-container ${sectionClass}`} id={`section${index + 1}`}>
                <div className={`project-spacer layer${index + 1} flip`}></div>
                 
                <section className={`project-section ${sectionClass}`}>
                  <div className="cloudbg-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}> 
                  </div>
                  <div className={`project-card project-card-box${index + 1}`}>
                    <h2>{title}</h2>
                    {descriptions.map((line, idx) => (
                     <p className={`details`} key={idx} >{line}</p>
                    ))}
                  </div>
                </section>
                <div className={`project-spacer layer${index + 1}`}></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  