import React from 'react';
import Typewriter from 'typewriter-effect';

const TerminalTypeEffect = () => {
  return (
    <div style={{ fontFamily: 'Inconsolata', fontSize: '30px', color: '#f06543', backgroundColor: 'transparent' }}>
      <Typewriter
        options={{
          strings: ['Welcome Here', 'Enjoy Your Stay!', 'What\'s up Brother ☝️', 
          "I'll be back.",
          "May the Force be with you.",
          "Hasta la vista, baby.",
          "You can't handle the truth!",
          "How you doin'?",
          "Life is like a box of chocolates.",
          "Say hello to my little friend!",
          "To infinity and beyond!",
          "Get to the chopper!",
          "They killed Kenny!",
          "I'm Batman.", 'Let Me Cook 🔥',],
          autoStart: true,
          loop: true,
          deleteSpeed: 35,
          delay: 75,
          cursor: '█',
          pauseFor: 2000, // How long to pause before starting to delete
        }}
      />
    </div>
  );
};

export default TerminalTypeEffect;
