import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import '../../styles/Nav.scss';

const NavControl = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className={`${isOpen ? 'open' : ''}`}>
            <a className="disc l1" onClick={toggleNav}>
                <div>About me</div> 
            </a>
            <a className="disc l2" onClick={toggleNav}>
                <Link to="/Projects" >Projects</Link>
            </a>
            <a className="disc l3" onClick={toggleNav}> 
                <Link to="/Resume" >Resume</Link>
            </a>
            <a className="disc l4" onClick={toggleNav}> 
                <Link to="/">Home</Link>
            </a>
            <a className="disc l5 toggle" onClick={toggleNav}>
                {isOpen ? 'Close' : (
                    <span>
                        <span className="hamburger-icon">&#9776;</span> {' - JH'}
                    </span>
                )}
            </a>
        </nav>
    );
};

export default NavControl;
