import React from 'react';
import { Link } from 'react-router-dom';
import {ResumeDownloadButton} from '../common/buttons';

const Links = ["https://ksuemailprod-my.sharepoint.com/:v:/g/personal/jakehoughton_ksu_edu/EVJyFEvdP_REgvsrI5WAGfYB72fKVZ6agGfjm-DBQawWoQ?e=GRyi4X&nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D",
                "/Projects#section2", "/Projects#section2", "/Projects#section2",
              ];

const ProjectContent = ({ project, imageAnimation, dividerAnimation, fadeInDirection, projectImages, selectedProject, handleProjectDotClick, projects }) => (
  <div>
    <div className="content__wrapper_indexed">
      <div className="content__text_indexed">
        <div className={`project-content ${fadeInDirection}`}>
          <h3 className={`title ${fadeInDirection}`} style={{ animationDelay: "0.1s" }}>{project.title}</h3>
          <div className="details">
            {project.descriptions.map((line, idx) => (
              <p key={idx} className={`${fadeInDirection}`} style={{ animationDelay: `${0.2 + idx * 0.1}s` }}>{line}</p>
            ))}
          </div>
          <Link className={`${fadeInDirection}`} style={{ animationDelay: `${project.descriptions.length * 0.1 + 0.3}s` }}
            to={`/Projects#section${project.id}`} >
            Learn more</Link>
        </div>
      </div>
      <div className={`content__separator_indexed ${dividerAnimation}`}></div>
      <div className="content__media_indexed">
        <Link to={Links[selectedProject]}>
          <img className={imageAnimation} src={projectImages[selectedProject]} alt="Projects" />
        </Link>
        <ResumeDownloadButton />
      </div>
    </div>
    <div className="dot-container">
      {projects.map((_, idx) => (
        <div
          key={idx}
          className={`dot ${selectedProject === idx ? "active" : ""}`}
          onClick={() => handleProjectDotClick(idx)}
          title={"Click to view " + projects[idx].title}
        ></div>
      ))}
    </div>
  </div>
);

export default ProjectContent;