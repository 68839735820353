import React, { useRef, useEffect, useState } from 'react';
import styles from '../styles/Home.module.css'
import Cloudbg from './common/Cloudbg'
import { Link } from 'react-router-dom'; 
import TerminalTypeEffect from './common/TerminalTypeEffect'; 
import {ResumeButton, ProjectButton} from './common/buttons'
import RippleCanvas from './common/RippleCanvas';
import boat from '../img/boat.png'
import SpaceBackground from './common/SpaceBg';


const Home = () => {   
    const flexChildRef = useRef(null);
    const [spaceBgSize, setSpaceBgSize] = useState({ width: 300, height: 300 });

    useEffect(() => {
        if (flexChildRef.current) {
          setSpaceBgSize({
            width: flexChildRef.current.offsetWidth,
            height: flexChildRef.current.offsetHeight
          });
        }
      }, [flexChildRef])

    return (
        <div className={styles['flex-container']}>
            <div className={styles['flex-child']} ref={flexChildRef}>
                <div className={styles['left-background']}>
                    <SpaceBackground width={spaceBgSize.width} height={spaceBgSize.height} />
                </div>
                <div className={styles['left-container']}> 
                
                    <div className={styles['name-label']}>
                        Jake Houghton, 
                    </div>
                    <div className={styles['type-writter']}>
                        <TerminalTypeEffect />
                    </div>
                    <div>
                        <p style={{ color: '#3b6064', marginTop: '25px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pellentesque pharetra posuere. 
                            Nam quis vulputate massa, efficitur tincidunt nibh. </p>
                    </div>
                    <div className={`${styles['flex-container-mini']}`}>  
                        <Link to="/Resume">
                            <ResumeButton />
                        </Link> 
                        <Link to="/Projects">
                            <ProjectButton />
                        </Link>
                    </div>  
  
                </div> 
            </div>
            <div className={`${styles['flex-child']} ${styles['flex-child-bot']}`}> 
                <img src={boat} className={styles.childImage} alt="Boat" />
                <RippleCanvas/> 
                <Cloudbg/>  
            </div>
        </div> 
    );
};

export default Home;
