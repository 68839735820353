//not sure if it's simpler or more complex to create many component instead of one big file
import React from 'react';

const TabContent = ({ content, isActive }) => {
  return (
    <li className={isActive ? "active" : "hidden"}>
      <div className="content__wrapper">
        {content}
      </div>
    </li>
  );
};

export default TabContent;