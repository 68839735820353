import React from 'react'; 
import {ResumeDownloadButton} from '../common/buttons';

const ExperienceContent = ({ experience, imageAnimation, dividerAnimation, fadeInDirection, handleExperienceDotClick, experienceImages, experiences, selectedExperience }) => (
  <div>
    <div className="content__wrapper_indexed">
      <div className="content__text_indexed">
        <div className={`experience-content ${fadeInDirection}`}>
          <div className={`title-period-container ${fadeInDirection}`} style={{ animationDelay: "0.1s" }}>
            <h3 className="title">{experience.title}</h3>
            <p className="period">{experience.period}</p>
          </div>
          <p className={`company ${fadeInDirection}`} style={{ animationDelay: "0.3s" }}>{experience.company}</p>
          <div className="details">
            {experience.details.map((detail, idx) => (
              <p key={idx} className={`${fadeInDirection}`} style={{ animationDelay: `${0.4 + idx * 0.1}s` }}>{detail}</p>
            ))}
          </div>
        </div>
      </div>
      <div className={`content__separator_indexed ${dividerAnimation}`}></div>
      <div className="content__media_indexed">
        <div className='experience-button'>
          <ResumeDownloadButton />
        </div>
        <img
          className={imageAnimation}
          src={experienceImages[selectedExperience]}
          alt="Experience"
          onClick={() => window.open(experienceImages[selectedExperience], "_blank", "noopener noreferrer")}
        />
      </div>
    </div>
    <div className="dot-container">
      {experiences.map((_, idx) => (
        <div
          key={idx}
          className={`dot ${selectedExperience === idx ? "active" : ""}`}
          onClick={() => handleExperienceDotClick(idx)}
          title={"Click to view " + experiences[idx].title}
        ></div>
      ))}
    </div>
  </div>
);

export default ExperienceContent;