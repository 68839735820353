import React, { useEffect } from 'react';
import Scrollbar from 'smooth-scrollbar'; 
import '../../styles/SkillsTab.scss';

const ScrollableSkillList = ({ skills, title }) => {
    useEffect(() => {
      const scrollContainer = document.querySelector(`.${title.replace(/\s+/g, '')}`);
      const scrollbar = Scrollbar.init(scrollContainer, {
        damping: 0.1,
        plugins: {
          overscroll: { effect: 'bounce' }
        }
      });

      scrollbar.addListener((status) => {
        const listItems = scrollContainer.querySelectorAll('.scroll-list__item');
        const viewportHeight = scrollbar.containerEl.clientHeight;
        
        listItems.forEach((item) => {
          // Calculate the top and bottom positions of the item relative to the viewport
          const itemTop = item.offsetTop - scrollbar.offset.y;
          const itemBottom = itemTop + item.offsetHeight;
      
          // Check if any part of the item is within the visible area of the viewport
          if (itemTop < viewportHeight && itemBottom > 0) {
            item.classList.add('item-focus');
            item.classList.remove('item-hide');
          } else {
            item.classList.remove('item-focus');
            item.classList.add('item-hide');
          }
        });
      }); 

      return () => scrollbar.destroy();
    }, []);
  
    return (
      <div className={`scroll-list__wrp ${title.replace(/\s+/g, '')}`}> 
        {skills.map((skill, index) => ( 
          <div key={index} className={`scroll-list__item ${index < 5 ? 'item-focus' : 'item-hide'}`}> 
            {skill}
            </div>
        ))}
      </div>
    );
  };
  
  const SkillsTab = () => {
    const categories = {
      programming: ["SQL", "PL/SQL", "Unix Shell Scripting", "XML", "C#", "Java", "C/C++", "Python"],
      webTech: ["AWS", "Azure", "HTML5", "CSS", "JavaScript", ".Net Framework 4.8", ".Net Core 6", "JBoss/Spring", "Node", "React", "JQuery"],
      developmentTools: ["The Linux Shell", "Regular Expressions", "IntelliJ Dojo", "PostgreSQL", "Data Structures", "ASP .NET Core", "Git", "CSS", "Agile", "Spring", "Linux", "Shell Scripting", "Concurrent Programming", "High Power Computing"]
    };
  
    return (
      <div className="scroll-list">
        <ScrollableSkillList skills={categories.programming} title="Programming and Scripting Languages" />
        <ScrollableSkillList skills={categories.webTech} title="Web and Cloud Technologies" />
        <ScrollableSkillList skills={categories.developmentTools} title="Development Tools and Methodologies" />
      </div>
    );
  };
  
  export default SkillsTab;