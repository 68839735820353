import React from 'react';
import {ResumeDownloadButton} from '../common/buttons';
import consturction1 from '../../img/construction10.gif';
import consturction2 from '../../img/consturction11.gif';
import aboutImage from '../../img/Image_About.jpg';

const AboutMeContent = ({ dividerAnimation }) => (
  <div>
    <div className="content__wrapper_indexed">
      <div className="content__text_indexed">
        <div style={{ marginTop: '10vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={consturction2} alt='Under Construction!' />
          <img src={consturction1} alt='Under Construction2!' />
        </div>
      </div>
      <div className={`content__separator_indexed ${dividerAnimation}`}></div>
      <div className="content__media_indexed">
        <img src={aboutImage} alt="About" />
        <div style={{ marginTop: '3vh' }}>
          <ResumeDownloadButton />
        </div>
      </div>
    </div>
  </div>
);

export default AboutMeContent;