import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../styles/ErrorPage.css';

function ErrorPage() {
  const location = useLocation();
  const message = location.state?.message || 'This is uncharted territory, Let\'s get you back to where it\'s safe ';

  return (
    <div>
      <div className='container'>
        <h1 className='error-header'>Happy Little Accident</h1>
        <p className='error-msg'>{message} <Link to="/">'/'</Link>!</p> 
        <img className='error-img' src="https://media.gettyimages.com/id/50467803/photo/tv-painting-instructor-artist-bob-ross-jubiantly-holding-up-paint-pallette-brushes-as-he-stands.jpg?s=612x612&w=0&k=20&c=LMajXi9w9M1LnnNfBcaIbiXZb6dICIQq48hS8Mo-xWQ=" alt="Bob Ross"></img>
      </div>
    </div>
  );
}

export default ErrorPage;