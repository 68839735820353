import React from 'react';
import SkillsTab from '../common/SkillsTab';

const SkillsContent = ({ isActive }) => {
  const animationClass = isActive ? "animate__animated animate__fadeIn" : "";

  return (
    <div className={`content__wrapper_indexed ${animationClass}`}>
      <div className="content_skill_text_indexed">
        <div className='content_skill_headers'>
          <h2>Programming and Scripting Languages</h2>
          <h2>Web and Cloud Technologies</h2>
          <h2>Development Tools and Methodologies</h2>
        </div>
        <SkillsTab />
      </div>
    </div>
  );
};

export default SkillsContent;