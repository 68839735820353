import React, {useState, useRef, useEffect} from 'react';
import Home from './Home';
import Resume from './Resume';
import Projects from './Projects'; 
import SpaceBackground from './common/SpaceBg';

const MainLayout = () => {
    const flexChildRef = useRef(null);
    const [spaceBgSize, setSpaceBgSize] = useState({ width: 300, height: 300 });

    const styles = {
        height: "95vh", 
        width: "100vw", 
        backgroundColor: "black"
      }; 
      
    useEffect(() => {
        if (flexChildRef.current) {
          setSpaceBgSize({
            width: flexChildRef.current.offsetWidth,
            height: flexChildRef.current.offsetHeight
          });
        }
      }, [flexChildRef])

    return (
        
        <div> 
            {/* <div style={styles} ref={flexChildRef}>
                <div styles={{height: "100%", width: "100%"}}>
                    <SpaceBackground width={spaceBgSize.width} height={spaceBgSize.height} />
                </div>
            </div> */}
            <Home /> 
            <Resume/>   
        </div>
    );
};

export default MainLayout;
