import React from 'react';
import '../../styles/buttons.scss'; 
 
const handleResumeDownload = () => {
    // Trigger the download when the button is hovered over
    const link = document.createElement('a');
    link.href = '/resume_Jake_H.pdf'; // Update the path to your local PDF file
    link.download = 'resume_Jake_H.pdf';
    link.click();
};


const ResumeButton= () => {
    return (
        <div class="button-container-1">
            <span class="mas">Resume</span>
            <button id='Resume' type="button" name="Hover">Resume</button>
        </div> 
    );
};

const ResumeDownloadButton = () => {
    return (
        <div class="button-container-2">
            <span class="mas2">Download Resume</span>
            <button type="button" name="Hover" onClick={handleResumeDownload}> 
                Download Resume 
            </button>
        </div> 
    );
};

const ProjectButton = () => {
    return (
        <div class="button-container-3">
            <span class="mas">Projects</span>
            <button type="button" name="Hover">Projects</button>
        </div> 
    );
}; 

export {ResumeButton, ResumeDownloadButton, ProjectButton};




