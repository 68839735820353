const projects = [
    { id: 1, title: "KSU Computer Science Department Custom Application", descriptions: [
      "• Led the development of a React and Node.js-based application for CS student course applications, significantly enhancing the user and admin experience with an updated layout, improved database management, and a modernized UI the 8-years-old application using React-Bootstrap. Also dramatically reduced the process administrative process from a few days to hours of work.",
      "• Actively managed the project lifecycle using SCRUM and Waterfall methodologies, facilitating bi-weekly meetings with product owners and stakeholders in a scrum setting, and conducting daily stand-ups to gather feedback and conduct sprint planning, ensuring the timely delivery of features while adapting to evolving requirements.",
      "• Configured AWS RDS for the development database and orchestrated backend implementations using Express and Knex, integrated KSU’s custom Central Authentication System, enhancing data storage scalability and streamlining API development for robust application functionality."
    ]},
    { id: 2, title: "KSRE Role Tool", descriptions: [
      "• Crafted a comprehensive class library, subsequently integrated as a DLL into ASP.NET Framework web applications.",
      "• The class library leverages newly designed and created tables, facilitating robust CRUD (Create, Read, Update, Delete) operations for managing roles and user permissions across various applications.",
      "• This integration has led to a more efficient and secure management of user roles and access within the system."
    ]},
    { id: 3, title: "KSU Directory County & District Tracker", descriptions: [
      "• Developed a new page for an ASP.NET Framework 4.8 web application, featuring innovative tables and custom-stored procedures from the database.",
      "• This page serves as a dynamic location tracker for users and agents, significantly enhancing user experience by eliminating the need to download and search through PDFs for information.",
      "• Implemented advanced query functionalities to streamline the process of locating individuals within the database."
    ]},  
    { id: 4, title: "KSU Directory County & District Tracker", descriptions: [
      "• Developed a new page for an ASP.NET Framework 4.8 web application, featuring innovative tables and custom-stored procedures from the database.",
      "• This page serves as a dynamic location tracker for users and agents, significantly enhancing user experience by eliminating the need to download and search through PDFs for information.",
      "• Implemented advanced query functionalities to streamline the process of locating individuals within the database."
    ]}, 
  ];
export default projects;