import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const SpaceBackground = ({ width, height }) => {
  const mountRef = useRef(null);

  const getRandomParticlePos = (particleCount) => {
    const arr = new Float32Array(particleCount * 3);
    for (let i = 0; i < particleCount; i++) {
      arr[i * 3] = (Math.random() - 0.5) * 20; // Increase the range to cover left and right ends
      arr[i * 3 + 1] = (Math.random() - 0.5) * 10;
      arr[i * 3 + 2] = (Math.random() - 0.5) * 5; // Spread stars along the z-axis
    }
    return arr;
  };

  const resizeRendererToDisplaySize = (renderer) => {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  };

  useEffect(() => {
    const mount = mountRef.current;
    if (!mount || width === 0 || height === 0) return;

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(width, height, false);
    mount.appendChild(renderer.domElement);

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 1.5, 1000);
    camera.position.z = 2;

    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(-1, 2, 4);
    scene.add(light);

    const geometrys = [new THREE.BufferGeometry(), new THREE.BufferGeometry()];
    geometrys[0].setAttribute('position', new THREE.BufferAttribute(getRandomParticlePos(350), 3));
    geometrys[1].setAttribute('position', new THREE.BufferAttribute(getRandomParticlePos(1500), 3));

    const loader = new THREE.TextureLoader();
    const materials = [
      new THREE.PointsMaterial({
        size: 0.05,
        map: loader.load("https://raw.githubusercontent.com/Kuntal-Das/textures/main/sp1.png"),
        transparent: true,
        color: 0xf06543
      }),
      new THREE.PointsMaterial({
        size: 0.075,
        map: loader.load("https://raw.githubusercontent.com/Kuntal-Das/textures/main/sp2.png"),
        transparent: true,
        color: 0xf06543
      })
    ];

    const starsT1 = new THREE.Points(geometrys[0], materials[0]);
    const starsT2 = new THREE.Points(geometrys[1], materials[1]);
    scene.add(starsT1);
    scene.add(starsT2);

    let mouseX = 0;
    let mouseY = 0;
    document.addEventListener("mousemove", (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    });

    const render = () => {
      if (resizeRendererToDisplaySize(renderer)) {
        const canvas = renderer.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
      }

      starsT1.position.x = mouseX * 0.0001;
      starsT1.position.y = mouseY * -0.0001;

      starsT2.position.x = mouseX * 0.0001;
      starsT2.position.y = mouseY * -0.0001;

      renderer.render(scene, camera);
      requestAnimationFrame(render);
    };
    requestAnimationFrame(render);

    return () => {
      document.removeEventListener("mousemove", (e) => {
        mouseX = e.clientX;
        mouseY = e.clientY;
      });
      if (renderer && mount) {
        mount.removeChild(renderer.domElement);
        scene.clear();
        renderer.dispose();
      }
    };
  }, [width, height]);

  return <div ref={mountRef} style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: -1 }} />;
};

export default SpaceBackground;
