import React, { useRef, useEffect, useState }  from 'react';
import 'animate.css';
import '../styles/Resume.scss'; 
import { Link } from 'react-router-dom'; 
import TabContent from './ResumeTabContents/TabContent';
import ExperienceContent from './ResumeTabContents/ExperienceContent';
import ProjectContent from './ResumeTabContents/ProjectContent';
import AboutMeContent from './ResumeTabContents/AboutMeContent';
import SkillsContent from './ResumeTabContents/SkillsContent';
import projects from './common/data';
import experienceImage1 from '../img/Image_Experiences.svg';
import experienceImage2 from '../img/Image_Experiences_2.png';
import projectImage4 from '../img/Image_Projects_4.png';

import projectImage1 from '../img/Image_Projects.jpg';
import projectImage2 from '../img/Image_Projects_2.jpg';
import projectImage3 from '../img/Image_Projects_3.jpg';
import SpaceBackground from './common/SpaceBg';
 
const Resume = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedExperience, setSelectedExperience] = useState(0);
  const [selectedProject, setSelectedProject] = useState(0);
  const [fadeInDirection, setFadeInDirection] = useState("animate__animated animate__lightSpeedInRight");
  const [imageAnimation, setImageAnimation] = useState('animate__animated animate__zoomIn');
  const [dividerAnimation, setDividerAnimation] = useState('animate__animated animate__jackInTheBox');

  const experienceImages = [experienceImage1, experienceImage2];
  const projectImages = [ projectImage4 , projectImage2, projectImage3, projectImage1];
  

  const tabs = ["About me", "Experience", "Projects", "Skills"]; 

  const determineFadeDirection = (currentIndex, newIndex) => {
    return currentIndex < newIndex ? 
           "animate__animated animate__customLightSpeedInRight" : 
           "animate__animated animate__customLightSpeedInLeft";
  }; 

  const flexChildRef = useRef(null);
    const [spaceBgSize, setSpaceBgSize] = useState({ width: 300, height: 300 });

    useEffect(() => {
        if (flexChildRef.current) {
          setSpaceBgSize({
            width: flexChildRef.current.offsetWidth,
            height: flexChildRef.current.offsetHeight
          });
        }
  }, [flexChildRef]);

  const experiences = [
    {
      title: "DevOps Developer ",
      period: "Sep. 2022 – May. 2024",
      company: "KSU IT",
      details: [
        "• Pioneered the development and implementation of a cross-application location tracking feature, utilizingthe.Net Framework 4.8 to optimize data flow, transforming the user experience from a manual PDF download-and-search process to an interactive UI. This enhancement allows users to search for location databy districts and counties within the state, significantly improving usability and efficiency.",
        "• Developed a custom .Net library and implemented user/role management across multiple web applications,using .NET Framework and Oracle DB. This innovation significantly reduced manual DevOps workload and enhanced administrative efficiency.",
        "• Actively participate in daily stand ups and code review to maintain critical applications as part of acolla borative team, ensuring optimal performance and reliability.",
        "• Managed and optimized database operations, ensuring data integrity and performance across multiple platforms, including Oracle DB, which supported critical application functionalities and user data management.",
        "• Utilized CI/CD pipelines using Jenkins for Java applications and AWS service for .Net applications.", 
        ],
        imageRefUrl: "https://k-state.edu"
    },
    {
        title: "Intern",
        period: "May. 2022 – Jun. 2022",
        company: "JNT Company",
        details: [
          "• Utilized the Laravel framework for backend development projects, enhancing system functionality and user experience.", 
          "• Developed an image toolkit allowing users to customize profile pictures via custom blade components and jQuery Guillotine, improving user interaction.",
          "• Implemented and tested follow/unfollow feature functionality, ensuring reliability and preventing issues."
        ],
        imageRefUrl: "https://www.jntcompany.com/"
    }
  ]; 
  
  const handleExperienceDotClick = (index) => {
    if (selectedExperience !== index) {
      document.documentElement.classList.add('isPlaying');
      const newFadeDirection = determineFadeDirection(selectedExperience, index);
      setFadeInDirection(''); 
      setImageAnimation('');
      setDividerAnimation('');
      
      setTimeout(() => { 
        setSelectedExperience(index);
        setImageAnimation('animate__animated animate__zoomIn');
        setDividerAnimation('animate__animated animate__jackInTheBox');
        setFadeInDirection(newFadeDirection); 
        document.documentElement.classList.remove('isPlaying');
      }, 50);
    }
  };
  
  const handleProjectDotClick = (index) => {
    if (selectedProject !== index) {
      document.documentElement.classList.add('isPlaying');
      const newFadeDirection = determineFadeDirection(selectedProject, index);
      setFadeInDirection(''); 
      setImageAnimation('');
      setDividerAnimation('');
      
      setTimeout(() => { 
        setSelectedProject(index);
        setImageAnimation('animate__animated animate__zoomIn');
        setDividerAnimation('animate__animated animate__jackInTheBox');
        setFadeInDirection(newFadeDirection); 
        document.documentElement.classList.remove('isPlaying');
      }, 50);
    }
  };

  const renderProject = () => {
    const project = projects[selectedProject];
    return (
      <ProjectContent
        project={project}
        imageAnimation={imageAnimation}
        dividerAnimation={dividerAnimation}
        fadeInDirection={fadeInDirection}
        projectImages={projectImages}
        selectedProject={selectedProject}
        handleProjectDotClick={handleProjectDotClick}
        projects={projects}
      />
    );
  };

  const renderExperience = () => {
    const experience = experiences[selectedExperience];
    return (
      <ExperienceContent
        experience={experience}
        imageAnimation={imageAnimation}
        dividerAnimation={dividerAnimation}
        fadeInDirection={fadeInDirection}
        experienceImages={experienceImages}
        experiences={experiences}
        selectedExperience={selectedExperience}
        handleExperienceDotClick={handleExperienceDotClick}
      />
    );
  };

  const tabContents = [
    {
      title: "About me",
      content: <AboutMeContent dividerAnimation={dividerAnimation} />
    },
    {
      title: "Experience",
      content: renderExperience()
    },
    {
      title: "Projects",
      content: renderProject()
    },
    {
      title: "Skills",
      content: <SkillsContent isActive={activeTabIndex === 3} />
    }
  ];

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };
//TODO: Fix flexbox for actual resume, resume should be in middle. SASS update also

  return (
    <div className="resume-container">
      <div className='top-section'  ref={flexChildRef}>
      {/* <div className='top-background'>
          <SpaceBackground width={spaceBgSize.width} height={spaceBgSize.height} />
        </div> */}
        <section className="wrapper">
          <ul className="tabs">
            {tabs.map((tab, index) => (
              <li key={index}
                  className={activeTabIndex === index ? "active" : ""}
                  onClick={() => handleTabClick(index)}>
                {tab}
              </li>
            ))}
          </ul>
          <ul className="tab__content">
            {tabContents.map((content, index) => (
              <TabContent
                key={index}
                content={content.content}
                isActive={activeTabIndex === index}
              />
            ))}
          </ul>
        </section>
      </div>
      <div className='bottom-section'>

      </div>
    </div>
  );
};

export default Resume;